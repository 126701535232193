import React, { useEffect } from "react";
import "../styles/index.scss";
import Head from "next/head";
import { useRouter } from "next/router";
import { SWRConfig } from "swr";
import Script from "next/script";


function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();


  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceWorker.js").then(function(registration) {
        console.log("Service Worker registration successful with scope: ", registration.scope);
      },
      function(err) {
        console.log("Service Worker registration failed: ", err);
      });
    }


    function routeChangeStart(url){
      if (window.handlePageExit){
        window.handlePageExit();
      }
    }

    //handle page view
    function routeChangeComplete(url){
      //window.pageId.url = url;
      //window.pageExited = false;
      //window.pageViewStartTime = Date.now();
    }

    //gtm
    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeComplete);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, [router.pathname, router.events]);

  return (
    <>
      <Head>
        <link rel="preload" href="/fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="preload" href="/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2" as="font" type="font/woff2" crossOrigin="true"/>

        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover" />

        <meta name="application-name" content="Lazy Prompts" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Lazy Prompts" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#090e2b" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/logos/lazyPrompts/favicon_192.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/logos/lazyPrompts/favicon_152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/logos/lazyPrompts/favicon_180.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/logos/lazyPrompts/favicon_167.png" />

        <link rel="icon" type="image/png" sizes="64x64" href="/logos/lazyPrompts/favicon_64.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/logos/lazyPrompts/favicon_32.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/images/logos/lazyPrompts/favicon_512.png" />

        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: process.env.tagJs }}></script>
      </Head>
      <SWRConfig value={{ }}>
        <Component {...pageProps} />
        <Script id="googleAnalytics" strategy="afterInteractive">
          {`setTimeout(()=>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-536DSQR');}, 1000)`}
        </Script>
      </SWRConfig>
    </>
  );
}

export default MyApp;
